.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.MuiOutlinedInput-notchedOutline {
  // border-width: 0px !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scroll-shadows {
  --bgRGB: 255, 255, 255;
  --bg: rgb(var(--bgRGB));
  --bgTrans: rgba(var(--bgRGB), 0);

  --shadow: #a2cdec;
  overflow: auto;

  background:
    /* Shadow Cover TOP */ linear-gradient(
        var(--bg) 100%,
        var(--bgTrans)
      )
      center top,
    /* Shadow Cover BOTTOM */ linear-gradient(var(--bgTrans), var(--bg) 0%)
      center bottom,
    /* Shadow TOP */
      radial-gradient(farthest-side at 50% 0, var(--shadow), rgba(0, 0, 0, 0))
      center top,
    /* Shadow BOTTOM */
      radial-gradient(
        farthest-side at 50% 100%,
        var(--shadow),
        rgba(0, 0, 0, 0)
      )
      center bottom;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 40px, 100% 40px;
  background-attachment: local, local, scroll, scroll;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input::before {
  cursor: pointer;
  color: #4b4d56;
  background-color: white;
  content: 'Edit';
  position: absolute;
  left: 72px;
  top: 72px;
  z-index: 100;
  text-align: center;
  display: inline-block;
  width: 48px;
  height: 24px;
  padding-top: 4px;
  font-size: 16px;
  border: 1px solid #999;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
}

.custom-file-input.big::before {
  right: 40px;
  bottom: 4px;
  left: auto;
  top: auto;
}

.custom-file-input-table-hidden {
  display: none !important;
}

.custom-file-input-table::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input-table::before {
  cursor: pointer;
  color: #4b4d56;
  background-color: white;
  content: 'Upload';
  z-index: 100;
  text-align: center;
  display: inline-block;
  margin-right: -80px;
  width: 64px;
  height: 24px;
  padding-top: 4px;
  font-size: 16px;
  border: 1px solid #999;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
}

.scroll-bar-styled::-webkit-scrollbar {
  width: 2px;
}

.scroll-bar-styled::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #a2cdec;
}

.scroll-bar-styled::-webkit-scrollbar-thumb {
  background-color: #2979b5;
  outline: 1px solid slategrey;
}

.timeline-header-detail-blue {
  width: 0 !important;
}

.calendar-header-blue > .rct-outer > .rct-scroll {
  height: auto !important;
  width: 0 !important;
}

.calendar-header-blue-open > .rct-outer > .rct-scroll {
  width: 100% !important;
}

.calendar-header-blue-open {
  max-width: 100% !important;
}

.timeline-header-blue {
  width: fit-content !important;
  height: auto !important;

  > .rct-outer {
    height: auto !important;
  }
}

.hide-item-assignments {
  display: none !important;
}

.double-item-assignments {
  height: 28px !important;
}

.assignments-service-time-row {
  height: 100px !important;
}

.assignments-item-task-row {
  height: 60px !important;
}

.group-service-assignments {
  height: 30px !important;
}

.calendar-header-blue {
  height: auto !important;
  > .rct-outer {
    height: auto !important;
    > .rct-sidebar {
      height: auto !important;
      > div > .rct-sidebar-row {
        height: auto !important;
      }
    }
  }
}

.assignments-detail-popup-body {
  overflow-y: scroll;
  margin-bottom: 1rem;
}

.rct-sidebar-row,
.react-calendar-timeline .rct-horizontal-lines {
  padding: 0px !important;
}

/* Not important to the functionality, just for this particular demo */

.pdf-viewer {
  margin-bottom: -20px;
}

.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.Toastify__progress-bar {
  background: #005DA2 !important;
}
.Toastify__progress-bar--info {
  background: #005DA2 !important;
}
.Toastify__progress-bar--success {
  background: #68A550 !important;
}
.Toastify__progress-bar--warning {
  background: #E79137 !important;
}
.Toastify__progress-bar--error {
  background: #CE5A55 !important;
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info{
  background: #005DA2 !important;}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success{
  background: #68A550 !important;}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning{
  background: #E79137 !important;}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: #CE5A55 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--default {
  color: white;
  background: #005DA2 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  background: #005DA2 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #68A550 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  background: #E79137 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  background: #CE5A55 !important;
}